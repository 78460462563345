<template>
  <div>
    <section class="questions-bank">
      <b-row align-v="center">
        <div class="questions-bank__filter-title">فلتر حسب :</div>
        <b-col
          lg="4"
          md="6"
          xs="6"
          class="questions-bank__filter-by-activity-container d-flex w-100"
        >
          <div class="questions-bank__activity-filter-title mr-1">اسم المادة:</div>
          <ek-input-select
            clearable
            @change="onChange($event)"
            :options="subjectOptions"
            v-model="quizDto.subjectId"
            name="filter"
            class="questions-bank__activity-filter-input"
          />
        </b-col>

        <b-col
          lg="4"
          md="6"
          xs="6"
          class="questions-bank__filter-by-ads-status-container d-flex"
        >
          <div class="questions-bank__status-filter-title mr-1">اسم الإختبار:</div>
          <ek-input-select
            clearable
            @change="setQuiz($event)"
            :options="quizeOptions"
            v-model="quizDto.quizId"
            name="filter"
            class="questions-bank__status-filter-input"
          />
        </b-col>
      </b-row>
    </section>
    <section>
      <QuizQuestions
        v-if="resultQuestionBank.length > 0"
        :isPlus="false"
        :headers="questionCols"
        :questions="resultQuestionBank"
      ></QuizQuestions>
      <div class="d-flex w-100 justify-content-center">
        <b-pagination
          class="m-auto"
          @input="fetchQuestions"
          :per-page="questionsPagination.pageSize"
          :total-rows="totalCount"
          v-model="questionsPagination.pageNumber"
        ></b-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import QuizQuestions from "../lessons/components/quiz/QuizQuestions.vue";

import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: {
    QuizQuestions,
  },
  props: ["id", "lessonId", "subjectId"],
  computed: {
    ...mapState({
      quizeOptions: ({ questionsBank }) => questionsBank.quizeOptions,
      subjectOptions: ({ subjects }) => subjects.subjectOptions,
      questionCols: ({ questionsBank }) => questionsBank.questionCols,
      questions: ({ questionsBank }) => questionsBank.questions,
      quizDto: ({ questionsBank }) => questionsBank.quizDto,
      questionsPagination: ({ questionsBank }) => questionsBank.questionsPagination,
      totalCount: ({ questionsBank }) => questionsBank.questionsDto.count,
    }),
    ...mapGetters(["resultQuestionBank"]),
  },
  methods: {
    ...mapActions([
      "getSubjectOptions",
      "getLessonQuiz",
      "deleteQuestions",
      "getQuizes",
      "getAllQuizQuestions",
    ]),
    sendId(id) {
      this.$store.commit("SET_ID_QUESTION", id);
    },
    fetchQuestions() {
      this.getAllQuizQuestions({
        SubjectId: this.quizDto.subjectId,
        QuizId: this.quizDto.quizId,
        PageNumber: this.questionsPagination.pageNumber,
        PageSize: this.questionsPagination.pageSize,
      });
    },

    // getQuizBySubject(id) {
    //     this.$store.commit("SET_QUIZ_OPTIONS", id);
    // }
    onChange(subjectId) {
      this.questionsPagination.pageNumber = 1;
      this.quizDto.quizId = "";
      this.getQuizes(subjectId);
      this.fetchQuestions();
    },
    setQuiz(event) {
      this.questionsPagination.pageNumber = 1;
      this.$store.commit("SET_ID_QUESTION", event);
      this.getLessonQuiz(event).then((res) => {
        this.$store.commit("SET_QUESTIONS_QUIZE", res.data.questions);
      });
      this.fetchQuestions();
    },
  },
  created() {
    this.fetchQuestions();
  },
};
</script>

<style lang="scss" scoped>
.questions-bank {
  &__filter-title {
    font-weight: bold;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__filter-container {
    display: flex;
    gap: 2rem;
  }

  &__filter-by-activity-container {
    display: flex;
  }

  &__activity-filter-title {
    margin-top: 1.4rem;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__activity-filter-input {
    flex: 1;
  }

  &__filter-by-ads-status-container {
    display: flex;
  }

  &__status-filter-title {
    margin-top: 1.4rem;

    font-size: 1.2rem;

    color: #6e6b7b;

    @media only screen and (max-width: 780px) {
      margin-left: 0px;
    }
  }

  &__status-filter-input {
    flex: 1;
  }
}
</style>
